import { API } from 'utils/api';
import sortBy from 'lodash/sortBy';
import { fetchAreaChartData, fetchPieChartData } from 'actions/charts';
import { errorHandler } from 'utils/error_handler';
import { findIndex } from 'lodash';
import {
  showHeaderWaring,
  hideHeaderWarning
} from 'actions/modals';
import { getSubscriptionStatus } from 'actions/scan_table';
import {
  fetchScansOverview
} from 'actions/scan_table';

export const downloadReport = (history) => async (dispatch) => {
  try {
    dispatch({ type: 'DOWNLOAD_REPORT_URL_REQUEST' });
    const { data } = await API.get('/orgs/pdf-csv/');
    sessionStorage.setItem(
      'pdf_csv_data',
      Object.keys(data).length === 0 ? '' : JSON.stringify(data)
    );
    dispatch({
      type: 'DOWNLOAD_REPORT_URL_RESPONSE',
      data: data
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const finishSetup = (history) => async (dispatch) => {
  try {
    dispatch({ type: 'SETUP_FINISHED_REQUEST' });
    const response = await API.put('/orgs/header/');
    if (response && response.status === 200) {
      dispatch({
        type: 'SETUP_FINISHED_RESPONSE',
        status: response.status
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchDashboardStatus = (history) => async (dispatch) => {
  try {
    dispatch({ type: 'DASHBOARD_STATUS_REQUEST' });
    dispatch(getSubscriptionStatus(history));
    const { data } = await API.get('/orgs/header/');
    if (data) {
      localStorage.setItem('dashboardStatus', JSON.stringify(data));
      dispatch({
        type: 'DASHBOARD_STATUS_SUCCESS',
        status: data.status,
        is_unvalidated: data.is_unvalidated,
        killed_by: data.killed_by,
        killed_ts: data.killed_ts,
        resumable: data.resumable,
        is_killed_scan: data.is_killed_scan,
        org_subscription: data.org_subscription,
        setup_finished: data.setup_finished,
        asm_score: data.asm_score,

      });
      dispatch(fetchScansOverview(history,data.org))
      let warning = sessionStorage.getItem('warningHiddenManually');
      if (data.is_unvalidated || data.is_killed_scan) {
        if (!warning || warning === 'false') {
          dispatch(showHeaderWaring());
        } else if (warning === 'true') {
          dispatch(hideHeaderWarning());
        }
      }
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchDashboardDataV2 = (history, scanType) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_DASHBOARD_DATA_V2_REQUEST' });
    if (JSON.parse(localStorage.getItem('AssetGroupParams'))) {
      const assetGroupParams = JSON.parse(localStorage.getItem('AssetGroupParams'))
        ? JSON.parse(localStorage.getItem('AssetGroupParams'))
        : [{ group_name: "Default Group", id: null }];
      const filterUrl = `${assetGroupParams[0].value ? `?asset_group=${assetGroupParams[0].value}` : ''}${scanType ? scanType : ''}` 
      const { data } = await API.get(`/dashboard/${filterUrl}`);
      dispatch(fetchAreaChartData(data.scans_delta));
      const pieData = sortBy(data.severity_count, 'severity_count');
      dispatch(fetchPieChartData(pieData));

      let IPs = [];
      data.geolocation.forEach((ipInfo) => {
        const isLocationSaved = findIndex(
          IPs,
          (ipSaved) =>
            ipSaved.latitude === ipInfo.latitude &&
            ipSaved.longitude === ipInfo.longitude
        );
        if (isLocationSaved >= 0) {
          IPs[isLocationSaved] = {
            ...IPs[isLocationSaved],
            ip: IPs[isLocationSaved].ip.concat(ipInfo.ip)
          };
        } else {
          IPs = IPs.concat({
            ...ipInfo,
            ip: [ipInfo.ip]
          });
        }
      });

      const dashboardData = {
        ...data,
        geolocation: IPs,
      };
      dispatch({
        type: 'FETCH_DASHBOARD_DATA_V2_SUCCESS',
        data: dashboardData
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNewFeedData = (type, history) => async (dispatch) => {
  try {
    dispatch({ type: 'FEED_DATA_REQUEST' });
    const { data } = await API.get(`orgs/rssfeed/?item_type=${type}`);
    dispatch({
      type: 'FEED_DATA_RESPONSE',
      data: data.results,
      Count: data.count,
      nextItems: data.next,
      previousItems: data.previous,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchNextFeedData = (history) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'FEED_DATA_REQUEST' });
    const nextUrl = getState().dashboard.nextItems;
    const { data } = await API.get(nextUrl);

    const feedData = getState().dashboard.data;
    const dataUpdated = feedData.concat(data.results);
    dispatch({
      type: 'FEED_DATA_RESPONSE',
      data: dataUpdated,
      Count: data.count,
      nextItems: data.next,
      previousItems: data.previous,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const seenRssFeed = (params, history) => async (dispatch) => {
  try {
    // dispatch({ type: "FETCH_ASSET_TARGET_REQUEST" });

    const response = await API.put(`/orgs/rssfeed/`, {
      feed_ids: params
    });
    if (response.status === 200 || response.status === 201) {
      dispatch(fetchNewFeedData('product-updates'))
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
