import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { useSelector, useDispatch } from 'react-redux';
import map from 'lodash/map';
import { Link, withRouter } from 'react-router-dom';
import {
  updateAllNotificationStatus,
  fetchLatestNotifications,
  updateLatestNotificationStatus
} from 'actions/scan_details';
import Loading from 'utils/loader';
import { updateNotificationId, fetchActivityNotifications } from 'actions/asset_table';
import { CustomTooltip } from 'components/tooltip';
// import NotificationBell from 'assets/Notification.png';
import arrowDown from 'assets/arrow_drop_down.png';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
const useStyles = makeStyles(() => ({
  typography: {
    width: '320px',
    backgroundColor: 'var(--color-bodyBG)',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.14)'
  },
  container: {
    // border: '2px solid var(--color-running)',
    padding: '3px 12px',
    borderRadius: 67,
    border: '1px solid #BABABA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 500,
    marginLeft: 'auto',
    marginRight: '20px',
    cursor: 'pointer'
  },
  viewAll: {
    padding: '1rem',
    color: 'var(--color-primary)',
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 700
  }
}));

function NotificationPopper(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLatestNotifications(props.history));
  }, [])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(fetchLatestNotifications(props.history));
  };
  const latestNotifications = useSelector(
    (state) => state.notifications.latestNotifications
  );
  const handleClose = () => {
    if (latestNotifications.length) {
      const document_ids = latestNotifications.filter(i => i.status === 'NEW').map(el => el.id)
      if (document_ids.length) {
        dispatch(updateLatestNotificationStatus({ document_ids }));
      }
    }
    setAnchorEl(null);
  };
  const handleClickNotification = (type, id, date) => {
    sessionStorage.setItem(
      'notificationId',
      JSON.stringify({
        type,
        id,
        date,
        location: props.history.location.pathname
      })
    );
    dispatch(updateNotificationId(id, date));
    props.history.push(`/notification/${type}`);
    handleClose()
  };


  const loading = useSelector((state) => state.notifications.loading);
  const unreadCount = useSelector((state) => state.notifications.unreadCount);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const renderNotifications = () =>
    map(latestNotifications, (item) => (
      // <Link >
      <div
        onClick={() =>
          item.event_type === "asset-count" || item.event_type === "dataleak-count" || item.event_type === "issue-count" || item.event_type === "third-party-asset-count" ?
            handleClickNotification(item.event_type, item.id, item.date) : props.history.push(`/attack-surface/asset-inventory?${item.event_type === 'new_port_list' ? 'ports=' : 'techs='}${item.metadata.asset_inventory_ids}&reset=`)
        }
        key={item.id}
        className={`notification 
      ${item.status === 'unread' ? 'unread_notification' : 'read_notification'
          }`}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontSize: '14px', fontWeight: 500 }}>{item.msg}</span>
          <span style={{ fontSize: '14px' }}>{item.date}</span>
        </div>
      </div>
      // </Link>
    ));

  return (
    <div className="notifications">
      <CustomTooltip title={`View Notifications (${unreadCount ? unreadCount : 0} unread)`}>
        <div className={classes.container} onClick={handleClick}>
          {/* <img
            src={NotificationBell}
            alt="notifications"
            style={{ width: 20, marginRight: 20 }}
          /> */}
          <Badge
            color="primary"
            overlap="rectangular"
            badgeContent={unreadCount ? unreadCount : 0}
            style={{ marginRight: 20 }}>
            <NotificationsIcon
              style={{ width: 20, color: 'var(--color-white)', fontSize: 24 }}
            />
          </Badge>
          <img src={arrowDown} alt="openArrow" style={{ width: 10, filter: 'invert(1)' }} />
        </div>
      </CustomTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="notification-popup"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <div className={classes.typography}>
          <div
            style={{
              // backgroundColor: 'var(--color-primary)',
              padding: '1rem',
              display: 'flex',
              borderBottom: '1px solid var(--color-grey)',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontWeight: 700,
              color: 'var(--color-text)'
            }}>
            <p>Notifications</p>
            <div
              style={{
                color: 'var(--color-primary)',
                cursor: 'pointer',
                textAlign: 'right',
                fontSize: '14px'
              }}
              onClick={() =>
                dispatch(updateAllNotificationStatus({ document_ids: latestNotifications.map(el => el.id) }, props.history))
              }>
              Mark all as read
            </div>
          </div>
          {loading ? (
            <Loading
              type="Triangle"
              height="20"
              width="20"
              loaderStyle={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '400px'
              }}
            />
          ) : (
            renderNotifications()
          )}
          <Link to="/notifications" onClick={handleClose}>
            <p
              style={{
                padding: '1rem',
                color: 'var(--color-primary)',
                cursor: 'pointer',
                textAlign: 'center',
                fontWeight: 700
              }}>
              View all Notifications
            </p>
          </Link>
        </div>
      </Popover>
    </div>
  );
}

export default React.memo(withRouter(NotificationPopper));
